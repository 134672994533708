<template>
  <div class="p-4 bg-white-rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :urls="urls"
      :isWarehouseRequired="true"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import ActionDialog from "@/own/components/warehouseManagement/addPutawayTemplate/ActionDialog.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_STRUCTURE,
  UPDATE_TABLE_DATA,
  SET_TABLE_SORTBY,
  SET_TABLE_FILTER,
  SET_TABLE_PERPAGE,
  SET_TABLE_PAGE,
} from "@/core/services/store/putawayRuleBasedTemplate.module";

export default {
  name: "PutawayRuleBasedTemplate",

  components: { DataTable },

  data: function () {
    return {
      componentForAdding: ActionDialog,
      componentForEditing: ActionDialog,
      delete_url: `${process.env.VUE_APP_BASE_URL}/warehouse_management/putaway_rule_based_templates/destroy`,
      table_state: {
        UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
        UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
        SET_TABLE_SORTBY: SET_TABLE_SORTBY,
        SET_TABLE_FILTER: SET_TABLE_FILTER,
        SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
        SET_TABLE_PAGE: SET_TABLE_PAGE,
      },
      store_names: {
        getTableFilters: "getPUTAWAYRULEBASEDTEMPLATETableFilters",
        getTableState: "getPUTAWAYRULEBASEDTEMPLATETableState",
        getTableData: "getPUTAWAYRULEBASEDTEMPLATETableData",
        getTableHeaders: "getPUTAWAYRULEBASEDTEMPLATETableHeaders",
        getTableProperties: "getPUTAWAYRULEBASEDTEMPLATETableProperties",
        getTablePermissions: "getPUTAWAYRULEBASEDTEMPLATETablePermissions",
      },
      urls: {
        status: `${process.env.VUE_APP_BASE_URL}/warehouse_management/putaway_rule_based_templates/status`,
      },
    };
  },

  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE);
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
  },

  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
